@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
html,
#root,
body {
  height: 100%;
  margin: 0;
}
@media screen and (min-width: 600px) {
  body {
    font-size: 18px;
  }
  section {
    position: relative;
    padding: 6rem;
    padding-bottom: 0;
  }
}
@media screen and (max-width: 599px) {
  body {
    font-size: 16px;
  }
  section {
    position: relative;
    padding: 1rem;
    padding-bottom: 0;
  }
}
body {
  margin: 0;
  padding: 0;
  background: #272b33;
  color: white;
  font-family: 'Montserrat', sans-serif;
  min-width: 320px;
  overflow-x: hidden;
}
button > span {
  font-family: 'Montserrat', sans-serif;
}
.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}
section h1 {
  text-align: center;
}
@import url('https://fonts.googleapis.com/css2?family=Cairo&family=Tajawal:wght@200&display=swap');

*{
  direction: rtl!important;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;
          font-family: 'Cairo', sans-serif;
}

.woot-widget-bubble.woot-elements--right{
  right: unset!important;
  left: 20px!important;
  bottom: 10px!important;
}

@media only screen and (min-width: 667px){
  .woot-widget-holder.woot-elements--right {
    right: unset!important;
    left: 20px!important;

}
}

.Cardiv{
  width: 100px;
  margin: 20px auto;
  background-color: rgb(255, 255, 255);
  border-radius: 20px ;
  padding: 5px;
  padding-bottom: 0;
  display: flex;
  justify-content: center;
}
.Cardiv img{
  height: 100px;

}
.hhh{
  color: #2bd8e4;
  margin-right: 5px;
}

a{
  text-decoration: none!important;
  color: #2bd8e4;

}

