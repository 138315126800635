.project-link {
  color: white;
  text-decoration: none;
  cursor: default;
}
.project-card-wrapper {
  margin: 20px 0px 20px 0px;
}
.project-card {
  padding: 10px 20px 10px 20px;
  border-radius: 5px;
  background-color: #3b4353;
  transition: 0.2s;
  cursor: pointer;
}
.project-card-wrapper:hover .project-card {
  background-color: #474f63;
  transform: translateX(-10px);
}
.project-name {
  display: flex;
  align-items: center;
  margin: 20px auto;
  transition: 0.2s;
}

.project-name:hover {
  display: flex;
  align-items: center;
  margin: 20px auto;
  transform: translateX(5px);
}

.project-title {
  margin: 0;
}
.octicon {
  margin-right: 10px;
}
.project-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 1;
}
.project-info-left {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}
.project-info-right {
  display: flex;
  justify-content: right;
}
.language {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.language-colour {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 5px;
}
.language-name {
  font-size: small;
}
.project-size {
  font-size: small;
}

@media screen and (max-width: 599px) {
  .project-card-wrapper:hover .project-card {
    background-color: #3b4353;
    transform: translateX(0);
  }
}
