.topButton {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: #2bd8e4;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 15px;
  font-size: 25px;
}
.topButton i {
  display: flex;
}
.topButton.on {
  opacity: 1;
  transition: opacity 0.1s linear;
}
.topButton.off {
  opacity: 0;
  transition: opacity 0.1s linear;
}

.woot-widget-bubble.woot-elements--right {
  background-color: #2bd8e4!important;
}