@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cairo&family=Tajawal:wght@200&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
#root,
body {
  height: 100%;
  margin: 0;
}
@media screen and (min-width: 600px) {
  body {
    font-size: 18px;
  }
  section {
    position: relative;
    padding: 6rem;
    padding-bottom: 0;
  }
}
@media screen and (max-width: 599px) {
  body {
    font-size: 16px;
  }
  section {
    position: relative;
    padding: 1rem;
    padding-bottom: 0;
  }
}
body {
  margin: 0;
  padding: 0;
  background: #272b33;
  color: white;
  font-family: 'Montserrat', sans-serif;
  min-width: 320px;
  overflow-x: hidden;
}
button > span {
  font-family: 'Montserrat', sans-serif;
}
.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}
section h1 {
  text-align: center;
}

*{
  direction: rtl!important;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;
          font-family: 'Cairo', sans-serif;
}

.woot-widget-bubble.woot-elements--right{
  right: unset!important;
  left: 20px!important;
  bottom: 10px!important;
}

@media only screen and (min-width: 667px){
  .woot-widget-holder.woot-elements--right {
    right: unset!important;
    left: 20px!important;

}
}

.Cardiv{
  width: 100px;
  margin: 20px auto;
  background-color: rgb(255, 255, 255);
  border-radius: 20px ;
  padding: 5px;
  padding-bottom: 0;
  display: flex;
  justify-content: center;
}
.Cardiv img{
  height: 100px;

}
.hhh{
  color: #2bd8e4;
  margin-right: 5px;
}

a{
  text-decoration: none!important;
  color: #2bd8e4;

}


/* color: #2bd8e4; */
/* color: #f3dfa2; */

@media screen and (min-width: 599px) {
  .profile {
    width: 120px;
    height: 120px;
  }
}
@media screen and (max-width: 599px) {
  .profile {
    width: 100px;
    height: 100px;
  }
}
@media screen and (min-height: 599px) {
  .scroll-down {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 170px;
  }
}
@media screen and (max-height: 599px) and (min-height: 410px) {
  .scroll-down {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 100px;
  }
}
@media screen and (max-height: 409px) {
  .scroll-down {
    display: none;
  }
}
.home {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: calc(100vh + 63px);
  z-index: 1000;
  border-bottom: 3px solid #f3dfa2;
}
.greeting {
  margin: -63px 20px 0 20px;
}
.hide {
  display: none;
}
.profile {
  border-radius: 50%;
  border: 0.2rem solid #f3dfa2;
}
.greeting-text {
  color: #2bd8e4;
/* color: #a9927d; */
  margin: 15px;
}
.name {
  color: #fff;
  font-size: 4.5rem;
}
.links {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px;
  max-width: 60px;
  margin: 20px auto 0 auto;
}
.links a,
.links img {
  width: 50px;
  height: 50px;
}
.scroll-down-element {
  display: inline-block;
}
.wave-emoji {
  -webkit-animation-duration: 1.8s;
          animation-duration: 1.8s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: wave;
          animation-name: wave;
  display: inline-block;
  transform-origin: 70% 70%;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.particles {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100vh);
  width: 100%;
}
@-webkit-keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(12deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(9deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(12deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(9deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.profile{
  display: none!important;
}


@media screen and (max-width: 599px) {
  .greeting-text {
    font-size: 0.7rem;
  }
  .name {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 330px) {
  .greeting-text {
    font-size: 0.5rem;
  }
  .name {
    font-size: 1rem;
  }
}
.react-reveal{
  opacity: unset!important;
}
.experience-list{
  padding-right: 0!important;
}
.navbar {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #23252a;
  border-bottom: 3px solid #f3dfa2;
  pointer-events: fill;
}
.navbar a {
  cursor: pointer;
  font-weight: 600;
  color: #2bd8e4;
}
.navlink-wrapper {
  width: 20%;
}
.navlinks {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
#hamburger-icon {
  width: 35px;
  height: 21px;
  position: relative;
  margin: 0px auto;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
#hamburger-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: white;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
#hamburger-icon span:nth-child(1) {
  top: 0px;
}
#hamburger-icon span:nth-child(2),
#hamburger-icon span:nth-child(3) {
  top: 9px;
}
#hamburger-icon span:nth-child(4) {
  top: 18px;
}
#hamburger-icon.open span:nth-child(1) {
  top: 9px;
  width: 0%;
  left: 50%;
}
#hamburger-icon.open span:nth-child(2) {
  transform: rotate(45deg);
}
#hamburger-icon.open span:nth-child(3) {
  transform: rotate(-45deg);
}
#hamburger-icon.open span:nth-child(4) {
  top: 9px;
  width: 0%;
  left: 50%;
}
@media screen and (min-width: 600px) {
  .profile {
    width: 120px;
  }
  .hamburger {
    display: none;
  }
  .mobilenav {
    display: none;
  }
}
@media screen and (max-width: 599px) {
  .navlinks {
    display: none;
  }
  .hamburger {
    position: absolute;
    right: 30px;
    cursor: pointer;
  }
}


.mobilenav-wrapper {
  position: absolute;
  top: 63px;
  width: 100%;
  clip: rect(0, 9999px, 9999px, -9999px);
}
.mobilenav {
  background-color: #1a212e;
}
.mobilenav.on {
  transition: transform ease-in-out 0.4s, opacity ease-out 0.3s;
  transform: translateY(0);
}
.mobilenav.off {
  transition: transform ease-in-out 0.4s, opacity ease-out 0.3s;
  transform: translateY(-100%);
}
.mobilenavlinks {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.mobilenavlink {
  /* text-align: left; */
  padding-top: 10px;
  padding-bottom: 10px;
}
.mobilenavlinks a {
  /* text-align: left; */
  font-size: larger;
}

*{
  direction: rtl!important;
}
.about {
  background-color: #2c323f;
}
.about-text {
  width: 95%;
  max-width: 1000px;
}
.about-text > ul {
  list-style-type: none;
}
.about-text > ul > li {
  margin-bottom: 10px;
}
.typewriter {
  display: flex;
  align-items: center;
}
.typewriter-start {
  padding-right: 5px;
}
.typewriter > p {
  margin: 0;
}
.textLink {
  color: #0be779;
  cursor: pointer;
}
.location-wrapper {
  display: flex;
  align-items: center;
  width: 70%;
}
.skills-wrapper {
  position: relative;
  width: 100%;
  max-width: 600px;
  text-align: center;
}
.skills {
  position: relative;
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin: 0 auto;
  width: 90%;
}
.skill-bar-wrapper {
  overflow: hidden;
  position: relative;
  display: flex;
  background-color: #3e4555;
  border-radius: 2px;
  height: 30px;
  margin-bottom: 10px;
}
.skill-bar {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  height: 30px;
  background-color: #0be779;
  border-radius: 2px 0px 0px 2px;
}
@media screen and (min-width: 960px) {
  .about-content {
    display: flex;
    justify-content: space-evenly;
  }
  .skill-name {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px 0px 0px 2px;
    left: 0;
    width: 80px;
    height: 30px;
    font-size: 12px;
    background-color: #39b175;
    font-weight: 600;
    color: white;
  }
}
@media screen and (max-width: 959px) and (min-width: 600px) {
  .about-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .skill-name {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px 0px 0px 2px;
    left: 0;
    width: 80px;
    height: 30px;
    font-size: 12px;
    background-color: #39b175;
    font-weight: 600;
    color: white;
  }
}
@media screen and (max-width: 599px) {
  .about-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .skills {
    width: 100%;
  }
  .skill-name {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px 0px 0px 2px;
    left: 0;
    width: 65px;
    height: 30px;
    font-size: 10px;
    background-color: #39b175;
    font-weight: 600;
    color: white;
  }
}


.liiii{
  border-top: 3px solid #f3dfa2;
  padding-top: 20px;
  margin-top: 100px;

}
.section-title {
  font-size: 25pt;
  margin-bottom: 10px;
}
.underline {
  border-top: 4px solid #2bd8e4;
  margin: 0 auto;
  margin-bottom: 15px;
  width: 70px;
}

.experience {
  position: relative;
  background-color: #2c323f;
}
.experience-content {
  margin: 0 auto;
  max-width: 1400px;
}
.experience-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  padding-left: 0;
  list-style: none;
  margin: 0 auto;
}
.experience-list > li {
  border-radius: 5px;
  margin: 20px 10px;
  width: 390px;
  min-width: 290px;
}
.experience-list > li > div {
  height: 100%;
}

.textLink {
  color: #0be779;
  cursor: pointer;
}

.experience-bg{
  background: linear-gradient(#f3dfa2, #c3b487)!important;
}

.experience-bgtow{
  background: linear-gradient(#2bd8e4, #62d7df)!important;

}
.experience-link {
  text-decoration: none;
  height: 100%;
  cursor: default;
}
.experience-card-wrapper {
  height: 100%;
  transition: 0.2s;
}
.experience-card {
  position: relative;
  background-color: #3b4353;
  border-radius: 5px;
  align-items: center;
  min-height: 158px;
  transition: 0.2s;
  height: 100%;
  /* cursor: pointer; */
}
.experience-card-wrapper:hover .experience-card {
  transform: translateY(-10px);
  background-color: #474f63;
}
.experience-card-top {
  position: relative;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.experience-card-top > h2 {
  position: absolute;
  top: 30px;
  text-align: center;
  font-weight: 700;
  margin: 0 30px;
  font-size: 22px;
  color: white;
  text-decoration: none;
  z-index: 1;
}
.image-wrapper {
  position: absolute;
  bottom: -50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.25);
}
.experience-card-bottom {
  position: relative;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  height: calc(100% - 150px);
  color: white;
  padding: 65px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.experience-card-bottom h2 {
  margin: 0;
}
.experience-card-bottom h3 {
  margin: 20px 0;
  font-size: 18px;
}
.experience-card-bottom ul {
  list-style-type: disc;
  text-align: left;
}
.experience-card-bottom li {
  font-size: 16px;
}
.experience-card-tech {
  margin-top: 20px;
}
.experience-card-tech > ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-top: -7px;
  margin-left: -7px;
}
.experience-card-tech > ul > li {
  font-size: 12px;
  font-weight: 600;
  margin-top: 7px;
  margin-left: 7px;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: rgb(94, 106, 128);
}
.experience-bg {
  position: absolute;
  border-radius: 5px 5px 0 0;
  width: 100%;
  height: 100%;
}
.experience-bgtow {
  position: absolute;
  border-radius: 5px 5px 0 0;
  width: 100%;
  height: 100%;
}
.logo-bg {
  border-radius: 50%;
}
.company-logo {
  max-width: 150px;
  z-index: 1;
  pointer-events: none;
}
@media screen and (max-width: 599px) {
  .experience-card-wrapper:hover .experience-card {
    transform: translateY(0);
    background-color: #3b4353;
  }
  .experience-card-bottom {
    padding: 65px 15px 20px 15px;
  }
}

.experience-card-bottom li {
  text-align: right;
}

.experience-card-tech > ul > li {
  background-color: rgba(94, 106, 128, 0);
}

.btn-hover {
  width: 200px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 20px;
  height: 55px;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
  moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  margin: auto auto;
}

.btn-hover:hover {
  background-position: 100% 0;
  moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}

.btn-hover {
  background-image: linear-gradient(
    to right,
    #f3dfa2,
    #bdae83,
    #f3dfa2,
    #bdae83
  );
  box-shadow: 0 2px 6px #dfce99a3;
  moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:active {
  transform: scale(0.9);
  box-shadow: 0 4px 15px 0 rgb(12, 175, 93 , 0.5);
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s;
}
.btn-hover:before,
.btn-hover:after {
  transition: all ease-in-out 0.5s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s;
}


.pupModelContener{
  position: relative;
  top: -215px;
  width: 100%;
  /* left: -70px; */
  /* margin-right: -500px; */
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  z-index: 1000;
  background-color: rgb(255, 255, 255);
  border-radius: 20px ;
  display: flex;
  justify-content: center;
  padding: 0;
  padding-bottom: 40px;
  margin-bottom: -150px;
}
.btnClose{
  background-color: #2bd8e4;
  width: 40px;
  font-size: 20px;
  font-weight: 900;
  position: absolute;
  top:0;
  right: 0;
  margin: 15px;
  border: none;
  cursor: pointer;
  border-radius: 50%;
}
.modeltext{
  color: #3b4353!important;

}
.projects {
  position: relative;
  background-color: #2c323f;
}
.projects-content {
  margin: 0 auto;
}
.projects-list {
  position: relative;
  padding-left: 0;
  list-style: none;
  margin: 0 auto;
  width: 90%;
}
.projects-content {
  max-width: 1000px;
}
.tagslist {
  display: flex;
  width: 90%;
  margin: 30px auto 0 auto;
}
.project-tags {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
}
.more-projects-wrapper {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 599px) {
  .projects-list {
    width: 100%;
  }
}

.project-link {
  color: white;
  text-decoration: none;
  cursor: default;
}
.project-card-wrapper {
  margin: 20px 0px 20px 0px;
}
.project-card {
  padding: 10px 20px 10px 20px;
  border-radius: 5px;
  background-color: #3b4353;
  transition: 0.2s;
  cursor: pointer;
}
.project-card-wrapper:hover .project-card {
  background-color: #474f63;
  transform: translateX(-10px);
}
.project-name {
  display: flex;
  align-items: center;
  margin: 20px auto;
  transition: 0.2s;
}

.project-name:hover {
  display: flex;
  align-items: center;
  margin: 20px auto;
  transform: translateX(5px);
}

.project-title {
  margin: 0;
}
.octicon {
  margin-right: 10px;
}
.project-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 1;
}
.project-info-left {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}
.project-info-right {
  display: flex;
  justify-content: right;
}
.language {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.language-colour {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 5px;
}
.language-name {
  font-size: small;
}
.project-size {
  font-size: small;
}

@media screen and (max-width: 599px) {
  .project-card-wrapper:hover .project-card {
    background-color: #3b4353;
    transform: translateX(0);
  }
}

.featured-project-link {
  color: white;
  text-decoration: none;
  cursor: default;
}
.featured-project-wrapper {
  margin: 20px 0px;
}
.featured-project {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #3b4353;
  padding: 10px 20px 10px 20px;
  border-radius: 5px;
  min-height: 158px;
  transition: 0.2s;
  cursor: pointer;
}
.featured-project-wrapper:hover .featured-project {
  transform: translateX(-10px);
  background-color: #474f63;
}
.featured-project-bg {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  width: 100%;
  height: 100%;
}
.featured-project-top {
  display: flex;
  margin-top: 20px;
}
.featured-project-image {
  margin: 0 auto 0 -10px;
  width: 180px;
  z-index: 1;
  transition: 0.2s;
}

.featured-project-image:hover {
  transform: translateX(5px);
}

.featured-project > p {
  z-index: 1;
}
@media screen and (max-width: 599px) {
  .featured-project-wrapper:hover .featured-project {
    transform: translateX(0);
    background-color: #3b4353;
  }
  .featured-project-image {
    margin: 0 auto 0 -7px;
    width: 140px;
  }
}

.contact {
  background-color: #2c323f;
}
.links {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px;
  max-width: 600px;
  margin: 50px auto 0 auto;
}
.links a, .links img {
  width: 40px;
  height: 40px;
}

.contact-form {
  margin: 40px auto 0 auto;
  width: 100%;
  max-width: 500px;
}
.contact-form {
  display: flex;
  flex-direction: column;
}
.email-success {
  text-align: center;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: white !important;
  -webkit-box-shadow: 0 0 0 30px #3b4353 inset !important;
  caret-color: white;
  direction: rtl!important;

}


*{
  font-family: 'Cairo', sans-serif!important;
}


.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-filled{
  position: absolute!important;
  right: 20px !important;
}
footer {
  background-color: #2c323f;
  padding-bottom: 10px;
}
.copyright {
  text-align: center;
}

.topButton {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: #2bd8e4;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 15px;
  font-size: 25px;
}
.topButton i {
  display: flex;
}
.topButton.on {
  opacity: 1;
  transition: opacity 0.1s linear;
}
.topButton.off {
  opacity: 0;
  transition: opacity 0.1s linear;
}

.woot-widget-bubble.woot-elements--right {
  background-color: #2bd8e4!important;
}
