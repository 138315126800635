.featured-project-link {
  color: white;
  text-decoration: none;
  cursor: default;
}
.featured-project-wrapper {
  margin: 20px 0px;
}
.featured-project {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #3b4353;
  padding: 10px 20px 10px 20px;
  border-radius: 5px;
  min-height: 158px;
  transition: 0.2s;
  cursor: pointer;
}
.featured-project-wrapper:hover .featured-project {
  transform: translateX(-10px);
  background-color: #474f63;
}
.featured-project-bg {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  width: 100%;
  height: 100%;
}
.featured-project-top {
  display: flex;
  margin-top: 20px;
}
.featured-project-image {
  margin: 0 auto 0 -10px;
  width: 180px;
  z-index: 1;
  transition: 0.2s;
}

.featured-project-image:hover {
  transform: translateX(5px);
}

.featured-project > p {
  z-index: 1;
}
@media screen and (max-width: 599px) {
  .featured-project-wrapper:hover .featured-project {
    transform: translateX(0);
    background-color: #3b4353;
  }
  .featured-project-image {
    margin: 0 auto 0 -7px;
    width: 140px;
  }
}
