.contact-form {
  margin: 40px auto 0 auto;
  width: 100%;
  max-width: 500px;
}
.contact-form {
  display: flex;
  flex-direction: column;
}
.email-success {
  text-align: center;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: white !important;
  -webkit-box-shadow: 0 0 0 30px #3b4353 inset !important;
  caret-color: white;
  direction: rtl!important;

}


*{
  font-family: 'Cairo', sans-serif!important;
}


.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-filled{
  position: absolute!important;
  right: 20px !important;
}