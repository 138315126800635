.experience-link {
  text-decoration: none;
  height: 100%;
  cursor: default;
}
.experience-card-wrapper {
  height: 100%;
  transition: 0.2s;
}
.experience-card {
  position: relative;
  background-color: #3b4353;
  border-radius: 5px;
  align-items: center;
  min-height: 158px;
  transition: 0.2s;
  height: 100%;
  /* cursor: pointer; */
}
.experience-card-wrapper:hover .experience-card {
  transform: translateY(-10px);
  background-color: #474f63;
}
.experience-card-top {
  position: relative;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.experience-card-top > h2 {
  position: absolute;
  top: 30px;
  text-align: center;
  font-weight: 700;
  margin: 0 30px;
  font-size: 22px;
  color: white;
  text-decoration: none;
  z-index: 1;
}
.image-wrapper {
  position: absolute;
  bottom: -50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  -webkit-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.25);
}
.experience-card-bottom {
  position: relative;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  height: calc(100% - 150px);
  color: white;
  padding: 65px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.experience-card-bottom h2 {
  margin: 0;
}
.experience-card-bottom h3 {
  margin: 20px 0;
  font-size: 18px;
}
.experience-card-bottom ul {
  list-style-type: disc;
  text-align: left;
}
.experience-card-bottom li {
  font-size: 16px;
}
.experience-card-tech {
  margin-top: 20px;
}
.experience-card-tech > ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-top: -7px;
  margin-left: -7px;
}
.experience-card-tech > ul > li {
  font-size: 12px;
  font-weight: 600;
  margin-top: 7px;
  margin-left: 7px;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: rgb(94, 106, 128);
}
.experience-bg {
  position: absolute;
  border-radius: 5px 5px 0 0;
  width: 100%;
  height: 100%;
}
.experience-bgtow {
  position: absolute;
  border-radius: 5px 5px 0 0;
  width: 100%;
  height: 100%;
}
.logo-bg {
  border-radius: 50%;
}
.company-logo {
  max-width: 150px;
  z-index: 1;
  pointer-events: none;
}
@media screen and (max-width: 599px) {
  .experience-card-wrapper:hover .experience-card {
    transform: translateY(0);
    background-color: #3b4353;
  }
  .experience-card-bottom {
    padding: 65px 15px 20px 15px;
  }
}

.experience-card-bottom li {
  text-align: right;
}

.experience-card-tech > ul > li {
  background-color: rgba(94, 106, 128, 0);
}

.btn-hover {
  width: 200px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 20px;
  height: 55px;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  margin: auto auto;
}

.btn-hover:hover {
  background-position: 100% 0;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}

.btn-hover {
  background-image: linear-gradient(
    to right,
    #f3dfa2,
    #bdae83,
    #f3dfa2,
    #bdae83
  );
  box-shadow: 0 2px 6px #dfce99a3;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:active {
  transform: scale(0.9);
  box-shadow: 0 4px 15px 0 rgb(12, 175, 93 , 0.5);
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s;
}
.btn-hover:before,
.btn-hover:after {
  transition: all ease-in-out 0.5s;
  transition: transform ease-in-out 0.1s, box-shadow ease-in-out 0.25s;
}


.pupModelContener{
  position: relative;
  top: -215px;
  width: 100%;
  /* left: -70px; */
  /* margin-right: -500px; */
  height: max-content;
  z-index: 1000;
  background-color: rgb(255, 255, 255);
  border-radius: 20px ;
  display: flex;
  justify-content: center;
  padding: 0;
  padding-bottom: 40px;
  margin-bottom: -150px;
}
.btnClose{
  background-color: #2bd8e4;
  width: 40px;
  font-size: 20px;
  font-weight: 900;
  position: absolute;
  top:0;
  right: 0;
  margin: 15px;
  border: none;
  cursor: pointer;
  border-radius: 50%;
}
.modeltext{
  color: #3b4353!important;

}