.contact {
  background-color: #2c323f;
}
.links {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px;
  max-width: 600px;
  margin: 50px auto 0 auto;
}
.links a, .links img {
  width: 40px;
  height: 40px;
}
